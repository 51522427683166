<template>
  <div>
    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      spinner="bar-fade-scale"
      text="Carregando Negociação"
    />

    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Dados da Negociação" active>
            <b-col class="content-tab" cols="8" xl="8">
              <label for="negociacao-composicao">Composição:</label>
              <b-form-input id="negociacao-composicao" v-model="detalhe.proposta.hitNomeComposicao" readonly></b-form-input>

              <label for="negociacao-valor">Valor:</label>
              <b-form-input id="negociacao-valor" :value="formatarMoeda(detalhe.proposta.valorVenda)" readonly></b-form-input>

              <label for="negociacao-tipo">Tipo:</label>
              <b-form-input id="negociacao-tipo" v-model="detalhe.proposta.tipoNegociacaoDescricao" readonly></b-form-input>

              <label for="negociacao-tempo">Tempo:</label>
              <b-form-input id="negociacao-tempo" v-model="detalhe.proposta.tempoUltimaPropostaDescricao" readonly></b-form-input>

              <label for="negociacao-tempo">Status negociação:</label>
              <b-form-input id="negociacao-tempo" v-model="detalhe.proposta.statusNegociacaoDescricao" readonly></b-form-input>
            </b-col>
          </b-tab>
          <b-tab title="Compositor">
            <b-col class="content-tab" cols="8" xl="8">
              <label for="compositor-nome">Nome:</label>
              <b-form-input id="compositor-nome" v-model="detalhe.usuarioCompositor.nome" readonly></b-form-input>

              <label for="compositor-cpf">CPF:</label>
              <the-mask id="compositor-cpf" v-model="detalhe.usuarioCompositor.cpf" :mask="['###.###.###-##']" class="form-control" readonly/>

              <label for="compositor-email">Email:</label>
              <b-form-input id="compositor-email" v-model="detalhe.usuarioCompositor.email" readonly></b-form-input>

              <label for="compositor-celular">Celular:</label>
              <the-mask id="compositor-celular" v-model="detalhe.usuarioCompositor.celular" :mask="['(##) #####-####']" class="form-control" readonly/>
                    
            </b-col>
          </b-tab>
          <b-tab title="Comprador">
              <b-col class="content-tab" cols="8" xl="8">
                <label for="comprador-nome">Nome:</label>
                <b-form-input id="comprador-nome" v-model="detalhe.usuarioComprador.nome" readonly></b-form-input>
                
                <label for="comprador-razao-social">Razão Social:</label>
                <b-form-input id="comprador-razao-social" v-model="detalhe.usuarioComprador.razaoSocial" readonly></b-form-input>

                <label for="comprador-cpf">CPF:</label>
                <the-mask id="comprador-cpf" v-model="detalhe.usuarioComprador.cpf" :mask="['###.###.###-##']" class="form-control" readonly/>
                
                <label for="comprador-cnpj">CNPJ:</label>
                <the-mask id="comprador-cpf" v-model="detalhe.usuarioComprador.cnpj" :mask="['##.###.###/####-##']" class="form-control" readonly/>

                <label for="comprador-email">Email:</label>
                <b-form-input id="comprador-email" v-model="detalhe.usuarioComprador.email" readonly></b-form-input>

                <label for="comprador-celular">Celular:</label>
                <the-mask id="comprador-celular" v-model="detalhe.usuarioComprador.celular" :mask="['(##) #####-####']" class="form-control" readonly/>
              
              </b-col>
          </b-tab>
          <b-tab title="Documentação" v-if="statusValido">

            <b-row>
              <b-col class="content-tab" cols="6" xl="6">
                <b-form v-if="!carregamentoApi" class="auth-register-form mt-2" @submit.prevent="register">
                
                  <b-form-group label="Status da proposta" label-for="statusNegociacaoSelecionado">
                    <v-select v-model="statusNegociacaoSelecionado" label="title" :options="statusNegociacaoEnum" />
                  </b-form-group>
    
                  <b-form-group label="Validade do documento" label-for="validade">
                      <flat-pickr
                        id="validade"
                        v-model="data"
                        :config="configData"
                        class="form-control"
                        placeholder="DD/MM/AAAA"
                      />
                  </b-form-group>

                  <div class="d-flex justify-content-between mt-3">
                        <b-button variant="danger" size="sm" @click="excluirDocumento()"> Excluir documento </b-button>
                        <b-button variant="dark" size="sm" @click="togglePDF()"> Visualizar PDF </b-button>
                        <b-button  variant="secondary" size="sm" @click="downloadHit()">Baixar a guia</b-button>
                          <b-button variant="primary" class="w-25" size="sm" type="submit" @click="atualizarStatusProposta" :disabled="invalid || respostaApi">
                            Salvar
                          <b-spinner v-if="respostaApi" small label="Carregando" />
                        </b-button>
                  </div>
                
                </b-form>
              </b-col>

              <b-col class="content-tab" cols="6" xl="6">
                    <div v-if="!showPdf"> 
                      <b-row cols="12" xl="6" class="d-flex spacer-down">
                          <b-col cols="12" xl="6" >
                            <b-button size="sm" @click="abrirModalStatus()"> Atualizar situação </b-button>
                          </b-col>
                      </b-row>
                    </div>
      
                    <div v-if="!showPdf" class="pdf-container">
                      <b-card bg-variant="light">
                        <small class="mb-1 text-danger">Esta negociação está sem documentação anexada. Antes de anexá-la, certifique-se de que o pagamento referente à venda foi efetuado.</small>
                        <b-form-file class="mt-1" v-model="hitDoc" :state="Boolean(hitDoc)" placeholder="Faça o upload da documentação..." drop-placeholder="Solte o aqruivo aqui..."/>
                        <b-button class="spacer" variant="primary" block  @click="uploadDocumento()"> Salvar documento </b-button>
                      </b-card>
                    </div>
              </b-col>
              <b-modal id="pdf-modal" ref="pdfModal" size="lg" title="Visualizar PDF" hide-footer>
                <iframe :src="pdfSrc" style="width:100%; height:80vh;" frameborder="0"></iframe>
              </b-modal>
              
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <!-- Modal de Status  Negociação -->
    <b-modal id="modal-status" ref="modalStatus" ok-only centered no-close-on-backdrop size="sm" title="Atualizar status da negociação">
      <template v-slot:modal-footer>
        <b-button block variant="primary" @click="atualizarStatus">
          Atualizar
        </b-button>
      </template>
      <b-row>
        <b-col>
          <v-select  v-model="statusNegociacaoSelecionado" label="title" :options="statusNegociacaoEnum" />
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormFile, BModal, BTab, BTabs,
  BFormGroup, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import VueElementLoading from 'vue-element-loading'
import useJwt from '@/auth/jwt/useJwt'
import AudioPlayer from '@liripeng/vue-audio-player'
import axios from 'axios'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'
import StatusNegociacao from '@/enums/StatusNegociacao'
import { TheMask } from "vue-the-mask";
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BForm,
    BFormFile,
    BFormGroup,
    vSelect,
    VueElementLoading,
    AudioPlayer,
    BModal,
    BTabs,
    BTab,
    TheMask,
    BSpinner,
    moment,
    flatPickr,
  },
  data() {
    return {
      detalhe: {
        proposta: {
          hitNomeComposicao: '',
          valorVenda: 0,
          tipoNegociacaoDescricao: '',
          tempoUltimaPropostaDescricao: '',
          statusNegociacaoDescricao: '',
        },
        usuarioCompositor: {
          nome: '',
          cpf: '',
          email: '',
          celular: ''
        },
        usuarioComprador: {
          nome: '',
          razaoSocial: '',
          cpf: '',
          cnpj: '',
          email: '',
          celular: ''
        }
      },
      invalid: false,
      propostaId: null,
      respostaApi: false,
      jwtConfig: { ...jwtDefaultConfig },
      data: null,
      addTemaSidebarAtivo: false,
      refUserListTable: null,
      carregamentoApi: false,
      showPdfField: true,
      showPdf: false,
      pdfSrc: '',
      hitDoc: null,
      statusNegociacaoValor: null,
      statusNegociacaoSelecionado: {},
      statusNegociacaoEnum: [
        { id: 5, title: 'Aguardando documentação' },
        { id: 6, title: 'Aguardando pagamento' },
        { id: 7, title: 'A venda do hit foi concluída' },
      ],
      configData: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        allowInput: true,
        locale: Portuguese, 
      },
    }
  },
  computed: {
    statusValido() {
      return [1, 2, 5, 6, 7].includes(this.statusNegociacaoValor);
    }
  },
  created() {
    this.carregarDetalhes()
  },
  methods: {
    formatarMoeda(valor) {
      if (valor === null || valor === undefined) {
        valor = 0;
      }
      return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })  
    },
    formatarDataTela(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    formatarData(date) {
      const data = date.split('T')[0];

      return moment(data, 'YYYY-MM-DD').format('YYYY-MM-DD');
    },
    carregarDetalhes() {
      this.carregamentoApi = true
      useJwt.gestaoNegociacaoDetalhe({
        propostaId: router.currentRoute.params.id,
      })
        .then(response => {
          this.detalhe = response.data;
          this.statusNegociacaoSelecionado = this.detalhe.proposta.statusNegociacaoDescricao
          this.statusNegociacaoValor = this.detalhe.proposta.statusNegociacaoValor
          this.data = this.detalhe.proposta.validade ? this.formatarData(this.detalhe.proposta.validade) : ''
          this.pdfSrc = this.detalhe.proposta.arquivoLocalArquivo
          if(this.pdfSrc){
            this.showPdf = true;
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    downloadHit() {
      this.carregamentoApi = true
      useJwt.downloadHit({
        hitId: this.detalhe.proposta.hitId,
      })
      .then(response => {
        const extensao = this.detalhe.proposta.hitArquivoLocalArquivo.split('.').pop();
        const nomeHit = `${this.detalhe.proposta.hitNomeComposicao}.${extensao}`;    

        const a = window.document.createElement('a');
        a.href = "data:audio/mp3; base64, " + response.data.file;
        a.download = nomeHit;

        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      })
      .catch(error => {
        console.error('Erro:', error);
      })
      .finally(() => {
          this.carregamentoApi = false
      })
    },
    uploadDocumento() {
      this.carregamentoApi = true
      const formData = new FormData()
      formData.append('arquivo', this.hitDoc)

      axios.post(`${this.jwtConfig.gestaoNegociacaoUploadDocEndpoint}/${router.currentRoute.params.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.$swal({
            title: 'Documentação Enviada!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            location.reload();
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Ops! Aconteceu um erro.',
            text: error.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .finally(() => {
        this.carregamentoApi = false
      })
    },
    excluirDocumento() {
      this.$swal({
        title: 'Deseja excluir esse documento?',
        text: 'Essa operação não poderá ser desfeita.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
         this.carregamentoApi = true
          useJwt.gestaoNegociacaoExcluirDoc({
            propostaId: router.currentRoute.params.id,
          })
          .then(response => {
            this.$swal({
              title: 'Documentação excluída!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              location.reload();
            })
          })
          .catch(error => {
            this.$swal({
              title: 'Ops! Aconteceu um erro.',
              text: error.response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .finally(() => {
            this.carregamentoApi = false
          })
        }
      }).catch(error => {})
    },  
    abrirModalStatus() {
      this.$refs.modalStatus.show()
    },
    atualizarStatus() {
     
      const formData = new FormData()
      formData.append('valor',  this.statusNegociacaoSelecionado.id)
      const val = this.statusNegociacaoSelecionado.id;

      this.carregamentoApi = true
      useJwt.gestaoNegociacaoAtualizarStatus({
        propostaId: router.currentRoute.params.id,
        valor: val,
      })
      .then(response => {
        this.$swal({
          title: 'Status atualizado!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          location.reload();
        })
      })
      .catch(error => {
        this.$swal({
          title: 'Ops! Aconteceu um erro.',
          text: error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      .finally(() => {
        this.carregamentoApi = false
        this.$refs.modalStatus.hide()
      })
    },
    atualizarStatusProposta() {

        this.carregamentoApi = true
        this.propostaId = router.currentRoute.params.id
        const dto = {
          valor: this.statusNegociacaoSelecionado.id === undefined ? this.detalhe.proposta.statusNegociacaoValor : this.statusNegociacaoSelecionado.id,
          validade: this.data
        }
        useJwt.gestaoNegociacaoAtualizarStatus({
          propostaId: this.propostaId,
          dto: dto
        })
          .then(response => { 
            this.$swal({
                    title: 'Status atualizado!',
                    text: 'O status da negociação foi atualizado com sucesso.',
                    icon: 'success',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
          })
          }).catch(error => {
                  this.$swal({
                      title: 'Erro!',
                      text: error.response.data.message,
                      icon: 'error',
                      customClass: {
                      confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
            })
            })
            .finally(() => {
              this.carregamentoApi = false
            })
      },
      togglePDF() {
        if (this.pdfSrc) {
          this.$refs.pdfModal.show()
        } else {
          this.$swal({
            title: 'Nenhum PDF encontrado',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }
  },
}

</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
  .per-page-selector {
    width: 90px;
  }
  .pdf-viewer {
    width: 100%;
    height: 350px;
  }
  .spacer { 
    margin-top: 20px;
  }
  .spacer-down {
    margin-bottom: 5px; 
  }
  
  .content-tab {
    margin-top: -30px;
  }

</style>
